import ArrowExpandIcon from '@/components/atoms/Icon/svg/ArrowExpandIcon'
import DashboardIcon from '@/components/atoms/Icon/svg/DashboardIcon'
import EditIcon from '@/components/atoms/Icon/svg/EditIcon'
import ExpandIcon from '@/components/atoms/Icon/svg/ExpandIcon'
import GraphIcon from '@/components/atoms/Icon/svg/GraphIcon'
import ReportIcon from '@/components/atoms/Icon/svg/ReportIcon'
import SettingIcon from '@/components/atoms/Icon/svg/SettingIcon'
import { ROLE } from '@/constant/role'
import { PRIVATE_ROUTES, ROUTES } from '@/routes'
import customTheme from '@/theme'
import useStore from '@/zustand/sotre'
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { TMenuItem } from '../Header'

interface TMenuBar {
    open: boolean
    expandedMenuId: number[]
    initialTabId: number[]
    drawerWidth: number
    handleDrawerOpen: () => void
    handleDrawerClose: () => void
    handleTabClick: (path?: string, id?: number) => void
}
type TMenuList = {
    id: number
    text: string
    icon: JSX.Element
    path?: string
    isActive?: boolean
    children?: (Required<Omit<TMenuItem, 'children' | 'icon'>> & { disabled?: boolean })[]
    disabled?: boolean
}
function MenuBar(props: TMenuBar) {
    const { open, expandedMenuId, initialTabId, drawerWidth, handleDrawerOpen, handleDrawerClose, handleTabClick } =
        props
    const path = typeof window !== 'undefined' ? window.location.pathname : ''
    const { storeState, isAdmin, isShowAllCompanyOption } = useStore()
    const [menuList, setMenuList] = useState<TMenuList[]>([
        { id: 1, text: 'ダッシュボード', icon: <DashboardIcon />, path: ROUTES.HOME, isActive: path === ROUTES.HOME },
    ])
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                background: customTheme.colors.lightGray,
            },
            drawerOpen: {
                width: drawerWidth,
                backgroundColor: customTheme.colors.lightGray,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            drawerClose: {
                backgroundColor: customTheme.colors.lightGray,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                width: theme.spacing(7) + 1,
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9) + 1,
                },
            },
            toolbar: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: theme.spacing(0, 1),
                // necessary for content to be below app bar
                ...theme.mixins.toolbar,
            },
            expandBtn: {
                padding: 0,
                position: 'fixed',
                top: 80,
                left: 59,
                transition: 'left 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                '&:hover': {
                    backgroundColor: customTheme.colors.white,
                },
            },
            expandBtnActive: {
                left: 242,
            },
            slogan: {
                color: customTheme.colors.primaryN,
                fontSize: 14,
                textAlign: 'center',
                width: 0,
                overflow: 'hidden',
                transition: 'width 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            },
            sloganActive: {
                width: '100%',
            },
            menuItemWrapper: {
                margin: '5px 10px',
                minHeight: 0,
                maxHeight: 55,
                borderRadius: 8,
                overflow: 'hidden',
                transition: 'all 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            },
            menuItem: {
                height: 55,
                borderRadius: 8,
                position: 'relative',
            },
            menuItemText: {
                fontSize: 14,
                fontWeight: 600,
            },
            menuItemActive: {
                backgroundColor: `${customTheme.colors.primaryN} !important`,
                color: customTheme.colors.white,
                '& svg > path': {
                    fill: customTheme.colors.white,
                },
                '&:hover': {
                    backgroundColor: customTheme.colors.primaryN,
                },
            },
            menuItemExpanded: {
                maxHeight: 200,
                minHeight: 40,
                paddingBottom: 15,
            },
            backgroundActive: {
                backgroundColor: customTheme.colors.expandBackground,
                '&:hover': {
                    backgroundColor: customTheme.colors.expandBackground,
                },
            },
            itemChildWrapper: {
                margin: '5px 20px',
            },
            menuItemChild: {
                height: 32,
                width: '100%',
                backgroundColor: customTheme.colors.white,
            },
        }),
    )
    const classes = useStyles()

    useEffect(() => {
        const input = {
            id: 2,
            text: 'データ入力',
            icon: <EditIcon />,
            children: [
                {
                  id: 2.1,
                  text: '企業単位算定',
                  path: ROUTES.INPUT_MONTH,
                  isActive: path.includes('months')
                },
                {
                  id: 2.2,
                  text: 'CSV一括アップロード',
                  path: ROUTES.INPUT_BULK_UPLOAD,
                  isActive: path.includes('bulk-upload')
                },
                // {
                //     id: 2.2,
                //     text: '製品単位算定（準備中）',
                //     path: ROUTES.INPUT_NEW,
                //     isActive: path.includes('new'),
                //     disabled: true,
                // },
            ],
        }
        const analysis = {
            id: 3,
            text: 'データ分析',
            icon: <GraphIcon />,
            children: [
                {
                    id: 3.1,
                    text: '企業全体',
                    path: ROUTES.ANALYSIS_ORGANIZATION,
                    isActive: path.includes('organization'),
                },
                {
                    id: 3.2,
                    text: '拠点別',
                    path: ROUTES.ANALYSIS_SITE,
                    isActive: path.includes('sites'),
                },
                {
                    id: 3.3,
                    text: 'CSV一括ダウンロード',
                    path: ROUTES.ANALYSIS_BULK_EXPORT,
                    isActive: path.includes('bulk-export'),
                },
            ],
        }
        const reporting = {
            id: 4,
            text: 'レポーティング',
            icon: <ReportIcon />,
            children: [
                {
                    id: 4.1,
                    text: '温対法',
                    path: ROUTES.ANTI_WARMING,
                    isActive: path.includes('anti-warming'),
                },
                {
                    id: 4.2,
                    text: '省エネ法',
                    path: ROUTES.ENERGY_SAVING,
                    isActive: path.includes('energy-saving'),
                },
            ],
        }
        // const more = {
        //     id: 4,
        //     text: '削減レコメンド',
        //     icon: <LightBulbIcon />,
        //     path: ROUTES.MORE,
        //     isActive: path.includes('more'),
        // }
        // const tree = {
        //     id: 5,
        //     text: 'カーボン・オフセット',
        //     icon: <TreeIcon />,
        //     path: ROUTES.TREE,
        //     isActive: path.includes('tree'),
        // }
        const management = {
            id: 5,
            text: '設定',
            icon: <SettingIcon />,
            children: [
                {
                    id: 5.1,
                    text: 'ユーザー一覧',
                    path: ROUTES.ACCOUNTS_SETTING,
                    isActive: path.includes('accounts'),
                },
                {
                    id: 5.2,
                    text: '企業情報',
                    path: ROUTES.COMPANY_SETTING,
                    isActive: path.includes('companies'),
                },
                // {
                //     id: 5.3,
                //     text: 'プラン情報',
                //     path: ROUTES.PLANS,
                //     isActive: path.includes('plans'),
                // },
            ],
        }
        if (isAdmin) {
            setMenuList((pre) => [
                ...pre,
                input,
                analysis,
                reporting,
                management,
                //  more, tree
            ])
        }
        if (storeState.user?.roleId === ROLE.general) {
            let children = [...analysis.children]
            if (!isShowAllCompanyOption) {
                children = children.slice(1)
            }
            setMenuList((pre) => [...pre, input, { ...analysis, children }, reporting])
        }
        if (storeState.user?.roleId === ROLE.viewer) {
            setMenuList((pre) => [
                ...pre,
                analysis,
                reporting,
                //  more, tree
            ])
        }
    }, [])

    // check activated router
    useEffect(() => {
        const currRoute =
            (path as string).length > 1 && (path as string).charAt((path as string).length - 1) === '/'
                ? (path as string).substr(0, (path as string).length - 1)
                : path
        if (isShowAllCompanyOption) PRIVATE_ROUTES.general.push(ROUTES.ANALYSIS_ORGANIZATION)
        if (menuList.length <= 1 && !storeState.user?.roleId) return
        if (storeState.user?.roleId === ROLE.admin && PRIVATE_ROUTES.admin.includes(currRoute)) return
        if (storeState.user?.roleId === ROLE.viewer && PRIVATE_ROUTES.viewer.includes(currRoute)) return
        if (storeState.user?.roleId === ROLE.general && PRIVATE_ROUTES.general.includes(currRoute)) return
        else navigate(ROUTES.NOT_FOUND)
    }, [storeState.user?.roleId])

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar} style={{ height: 74 }}>
                <img
                    src="/assets/logo/logo_EV.png"
                    onClick={() => navigate(ROUTES.HOME)}
                    alt="logo"
                    width={'90%'}
                    style={{ cursor: 'pointer' }}
                />
            </div>
            <div style={{ width: '100%', height: 40, position: 'relative' }}>
                <div className={`${classes.slogan} ${open ? classes.sloganActive : ''}`}>Powered by Bright Innovation</div>
                <IconButton
                    onClick={open ? handleDrawerClose : handleDrawerOpen}
                    className={`${classes.expandBtn} ${open ? classes.expandBtnActive : ''}`}
                >
                    {<ArrowExpandIcon direction={open ? 'left' : 'right'} />}
                </IconButton>
            </div>
            <List>
                {menuList.map((item) => (
                    <div
                        className={`${classes.menuItemWrapper} ${
                            open && expandedMenuId.includes(item.id) && item.children?.length
                                ? classes.menuItemExpanded
                                : ''
                        } ${initialTabId.includes(item.id) ? classes.backgroundActive : ''}`}
                        key={item.id}
                    >
                        <div
                            onClick={() => {
                                if (item?.disabled) return
                                handleTabClick(item?.path, item.id)
                            }}
                        >
                            <ListItem
                                button
                                className={`${classes.menuItem} ${item.isActive ? classes.menuItemActive : ''}`}
                                disabled={item?.disabled}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} classes={{ primary: classes.menuItemText }} />
                                {item?.children && (
                                    <ExpandIcon
                                        direction={
                                            expandedMenuId.includes(item.id) && item.children?.length ? 'up' : 'down'
                                        }
                                    />
                                )}
                            </ListItem>
                        </div>
                        {open &&
                            item.children?.map((item) => (
                                <div key={item.id} className={classes.itemChildWrapper}>
                                    <div
                                        onClick={() => {
                                            if (item?.disabled) return
                                            handleTabClick(item.path, item.id)
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={`${classes.menuItem} ${classes.menuItemChild} ${
                                                item.isActive ? classes.menuItemActive : ''
                                            } ${expandedMenuId.includes(item.id) ? classes.menuItemExpanded : ''}`}
                                            disabled={item?.disabled}
                                        >
                                            <ListItemText
                                                primary={item.text}
                                                classes={{ primary: classes.menuItemText }}
                                            />
                                        </ListItem>
                                    </div>
                                </div>
                            ))}
                    </div>
                ))}
            </List>
        </Drawer>
    )
}

export default MenuBar
